import axios from "axios";

const API = "https://lyve-api.wiessolution.com/api";
const MYLYVE_API = axios.create({
  baseURL: API,
});
const buildConfig = (accessToken) => {
  const authString = "Bearer ".concat(accessToken);
  const config = {
    headers: { Authorization: authString },
  };
  return config;
};

// Login
export const Login = (values) => {
  return MYLYVE_API.post(`/candidate/login`, values);
};

// Login
export const request_for_otp = (values) => {
  return MYLYVE_API.post(`/candidate/request_for_otp`, values);
};

// resent otp
export const request_for_resent_otp = (values) => {
  return MYLYVE_API.post(`/candidate/request_for_resent_otp`, values);
};

// resent otp
export const login_with_otp = (values) => {
  return MYLYVE_API.post(`/candidate/login_with_otp`, values);
};

// Change Password
export const ChangePasswordaction = (values, token) => {
  return MYLYVE_API.post(
    `/v1/candidate/candidate_password_update`,
    values,
    buildConfig(token)
  );
};

// Reset Password
export const PasswordReset = (values, token) => {
  return MYLYVE_API.post(
    `/v1/candidate/candidate_initial_password_update`,
    values,
    buildConfig(token)
  );
};

// Logout
export const Logout = (token) => {
  return MYLYVE_API.post(`/v1/candidate/logout`, "", buildConfig(token));
};

// GET onboarding
export const getOnboarding = (id, token) => {
  return MYLYVE_API.get(`/v1/candidate/onboarding/${id}`, buildConfig(token));
};

// POST onboarding
export const updateOnboarding = (id, values, token) => {
  console.log(values);
  return MYLYVE_API.post(
    `/v1/candidate/onboarding/${id}`,
    values,
    buildConfig(token)
  );
};

// POST onboarding
export const updateradid = (id, values, token) => {
  return MYLYVE_API.post(
    `/v1/candidate/rad_id_mapping`,
    values,
    buildConfig(token)
  );
};

// POST onboarding
export const updatefailradid = (values, token) => {
  return MYLYVE_API.post(
    `/v1/candidate/update_documents`,
    values,
    buildConfig(token)
  );
};

// GET APP VERSION
export function get_app_version_info() {
  return new Promise((resolve, reject) => {
    MYLYVE_API.get("/v1/get_app_version_info")
      .then(function (response) {
        console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        console.log(error);
        reject(error.response.data.error);
      });
  });
}

// UPDATE TRAINING STATUS
export function update_training(values, token) {
  console.log(values);
  return new Promise((resolve, reject) => {
    MYLYVE_API.post(
      "/v1/candidate/update_training_status",
      values,
      buildConfig(token)
    )
      .then(function (response) {
        console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        console.log(error);
        reject(error.response.data.error);
      });
  });
}
