/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef, useContext } from "react";
import {
  Typography,
  Layout,
  Checkbox,
  DatePicker,
  Select,
  Spin,
  Table,
  Badge,
  Descriptions,
} from "antd";
import {
  Button,
  message,
  Steps,
  Radio,
  Form,
  Input,
  Col,
  Row,
  Skeleton,
} from "antd";
import {
  UserOutlined,
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";

import {
  getOnboarding,
  updateOnboarding,
  updateradid,
  updatefailradid,
  get_app_version_info,
  update_training,
} from "./api.js";
import bcgimg from "./asset/bcg.svg";
import bcgfail from "./asset/bcg-fail.svg";
import SignatureCanvas from "react-signature-canvas";
import { GlobalContext } from "./GlobalContext.js";
import TopHeader from "./Header.js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FileUpload from "./FileUpload.js";
import FileUploadDoc from "./FileUploadDoc.js";
import usstate from "./asset/data.json";
import PdfViewer from "./pdf.js";
import PDFReader from "./PdfReader.js";
import { Document, Page, pdfjs } from "react-pdf";
import ControlPanel from "./ControlPanel";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { Content, Footer } = Layout;
const { Paragraph, Title } = Typography;
const { Option } = Select;

const steps = [
  {
    title: "Personal info",
    icon: <UserOutlined />,
  },
  {
    title: "Address info",
    icon: <UserOutlined />,
  },
  {
    title: "Additional info",
    icon: <UserOutlined />,
  },
  {
    title: "Terms&Condi.",
    icon: <UserOutlined />,
  },
  {
    title: "Summary",
    icon: <UserOutlined />,
  },
  {
    title: "Training",
    icon: <UserOutlined />,
  },
  {
    title: "Completed",
    icon: <UserOutlined />,
  },
];

export default function OnBoarding() {
  const { user, setUser } = useContext(GlobalContext);
  const [userData, setUserData] = useState();
  const [form] = Form.useForm();
  const [dataloading, setdataloading] = useState(false);
  const [loading, setloading] = useState(false);
  const [onboardingdata, setonboardingdata] = useState("");
  const [current, setCurrent] = useState(3);
  const [signature, setSignature] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [fileList1, setFileList1] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const [fileList4, setFileList4] = useState([]);
  const [fileList5, setFileList5] = useState([]);
  const [photourl, setphotourl] = useState("");
  const [governmenturl, setgovernmenturl] = useState("");
  const [retailerurl, setretailerurl] = useState("");
  const [supporturl, setsupporturl] = useState("");
  const [retailerurl1, setretailerurl1] = useState("");
  const [supporturl1, setsupporturl1] = useState("");
  const [appVer, setAppVer] = useState("");
  const [radid, setradid] = useState("");
  const [isRADIDError, setRADIDError] = useState(false);
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
    sessionStorage.setItem("pageNumber", numPages);
  }

  const sigRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    setphotourl(onboardingdata?.photo_copy_url ?? "");
    setgovernmenturl(onboardingdata?.government_document_url ?? "");
    setretailerurl(onboardingdata?.retailer_agent_agreement_url ?? "");
    setsupporturl(onboardingdata?.support_document_url ?? "");
  }, [onboardingdata]);

  useEffect(() => {
    if (user) {
      setdataloading(true);
      getOnboarding(user?.candidate_id, user?.token)
        .then((res) => {
          console.log(res.data.items);
          setUserData(res.data.items);
          setonboardingdata(res?.data?.items);
          setCurrent(res?.data?.items?.completed_step + 1);
          setdataloading(false);
        })
        .catch((err) => {
          console.log(err.response.status);
          setdataloading(false);
          if (err.response.status === 401) {
            setUser("");
            navigate("/");
          }
        });
    } else {
      setUser("");
      navigate("/");
    }

    get_app_version_info()
      .then((res) => {
        setAppVer(res.data[0].application_version);
      })
      .catch((err) => {});
  }, [user]);
  const getdata = () => {
    setdataloading(true);
    getOnboarding(user?.candidate_id, user?.token)
      .then((res) => {
        console.log(res);
        setonboardingdata(res?.data?.items);
        setdataloading(false);
      })
      .catch((err) => {
        console.log(err);
        setdataloading(false);
        message.error(err?.response?.data?.error ?? "Something went wrong!");
      });
  };

  const submitData = (values) => {
    setloading(true);
    updateOnboarding(
      user?.candidate_id,
      { ...values, _method: "PUT", step: current },
      user?.token
    )
      .then((res) => {
        setloading(false);
        setCurrent(current + 1);
        getdata();
      })
      .catch((err) => {
        setloading(false);
        message.error(err?.response?.data?.error ?? "Something went wrong!");
      });
  };
  // step 1 submit
  const onFinish = (values) => {
    submitData({ ...values, dob: moment(values?.dob).format("MM-DD-YYYY") });
  };
  // step 2 submit
  const onFinishAddress = (values) => {
    submitData({ ...values });
  };
  // // step 3 submit
  // const onFinishAdditional = (values) => {
  //   values.rad_id = values?.rad_id ?? "";
  //   submitData({ ...values });
  // };
  // step 3 submit
  const onFinishProof = (values) => {
    if (fileList?.length > 0 || fileList1?.length > 0) {
      const formdata = new FormData();
      formdata.append("step", "3");
      formdata.append("_method", "PUT");
      fileList.forEach((element) => {
        formdata.append("government_document", element);
      });
      fileList1.forEach((element) => {
        formdata.append("photo_copy", element);
      });
      setloading(true);
      updateOnboarding(user?.candidate_id, formdata, user?.token)
        .then((res) => {
          console.log(res);
          setloading(false);
          setCurrent(current + 1);
          setFileList([]);
          setFileList1([]);
          getdata();
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
          message.error(err?.response?.data?.error ?? "Something went wrong!");
        });
    } else {
      setCurrent(current + 1);
    }
  };
  // step 4 submit
  const onFinishTerms = (values) => {
    if (!onboardingdata?.terms_check || !onboardingdata?.signature) {
      const formdata = new FormData();
      formdata.append("step", "4");
      formdata.append("_method", "PUT");
      formdata.append("signature", signature);
      formdata.append("terms_check", values?.terms_check ? 1 : 0);
      setloading(true);
      updateOnboarding(user?.candidate_id, formdata, user?.token)
        .then((res) => {
          setloading(false);
          setCurrent(current + 1);
          setloading(false);
          getdata();
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
          message.error(err.response.data.error);
        });
    } else {
      setCurrent(current + 1);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const prev = () => {
    setCurrent(current - 1);
    getdata();
  };
  const items = steps.map((item, i) => ({
    key: i + 1,
    title: item.title,
  }));

  const handleSignatureEnd = () => {
    var blobBin = atob(sigRef.current.toDataURL().split(",")[1]);
    var array = [];
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)], { type: "image/png" });
    setSignature(file);
  };
  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };
  useEffect(() => {
    form.setFieldsValue({
      ...onboardingdata,
      dob: onboardingdata?.dob
        ? moment(onboardingdata?.dob ?? "")
        : moment().subtract(18, "year"),
      is_rad: !!onboardingdata?.rad_check,
    });
  }, [form, onboardingdata, current]);

  // console.log(usstate);
  // const radidupdate = () => {
  //   setdataloading(true);
  //   var obj_arr = {
  //     rad_id: radid,
  //     candidate_id: user?.candidate_id,
  //   };
  //   console.log("radif", obj_arr);
  //   updateradid(user?.candidate_id, obj_arr, user?.token)
  //     .then((res) => {
  //       console.log(res);
  //       message.success(res?.data?.message);
  //       getdata();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setdataloading(false);
  //       message.error(err?.response?.data?.error ?? "Something went wrong!");
  //     });
  // };

  const radfailupdate = (values) => {
    setdataloading(true);
    const formdata = new FormData();

    fileList4.forEach((element) => {
      formdata.append("retailer_agent_agreement", element);
    });
    fileList5.forEach((element) => {
      formdata.append("support_document", element);
    });
    // formdata.append("rad_id", values.update_rad_id);
    formdata.append("candidate_id", user?.candidate_id);

    updatefailradid(formdata, user?.token)
      .then((res) => {
        console.log(res);
        message.success(res?.data?.message);
        getdata();
      })
      .catch((err) => {
        console.log(err);
        setdataloading(false);
        message.error(err?.response?.data?.error ?? "Something went wrong!");
      });
  };

  const onBoardTraining = () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("candidate_id", user?.candidate_id);
    formdata.append("training_check", 0);
    update_training(formdata, user?.token)
      .then((res) => {
        console.log(res);
        setloading(false);
        setCurrent(current + 1);
        getdata();
        message.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
        message.error(err?.response?.data?.error ?? "Something went wrong!");
      });
  };

  const finishTraining = () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("candidate_id", user?.candidate_id);
    formdata.append("step", "7");
    if (pageNumber === 8) {
      formdata.append("training_check", 1);
    } else {
      formdata.append("training_check", 0);
    }
    console.log(formdata);
    formdata.forEach((value, key) => {
      console.log(key + ": " + value);
    });
    update_training(formdata, user?.token)
      .then((res) => {
        console.log(res);
        setloading(false);
        setCurrent(current + 1);
        getdata();
        message.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
        message.error(err?.response?.data?.error ?? "Something went wrong!");
      });
  };

  // useEffect(() => {
  //   if (window.innerWidth < 520) {
  //     setScale(0.47);
  //   }
  // }, [window.innerWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScale(window.outerWidth / 1450); // You can adjust the division factor as needed
    };

    // Set initial scale
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Layout className="layout">
        <TopHeader />
        <Spin spinning={dataloading}>
          <div className="container p-0 form-holder-step">
            <Steps
              type="navigation"
              size="small"
              className="site-navigation-steps"
              current={current - 1}
              items={items}
              responsive={false}
            />
          </div>
          <Content
            className="container p-0"
            style={{ minHeight: "calc(100vh - 182px)" }}
          >
            <div className="site-layout-content">
              {/* <div style={contentStyle}>{steps[current].content}</div> */}
              <div>
                {current === 1 && (
                  <div className="container-fluid">
                    <h5 className="mb-0">Candidate Details</h5>
                    <hr />
                    <Form
                      form={form}
                      name="candidateprofile"
                      layout="vertical"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Row gutter={24}>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="First Name"
                            name="first_name"
                            rules={[
                              {
                                required: true,
                                message: "Please input first name",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Firstname" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="Middle Name (Optional)"
                            name="middle_name"
                            rules={[
                              {
                                required: false,
                                message: "Please input middle name",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Middle name" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="Last Name"
                            name="last_name"
                            rules={[
                              {
                                required: true,
                                message: "Please input last name",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Lastname" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please input valid email!",
                              },
                            ]}
                          >
                            <Input type="email" placeholder="Enter Email" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Phone Number"
                            name="mobile_number"
                            rules={[
                              {
                                required: true,
                                message: "Please input Phone Number!",
                              },
                            ]}
                          >
                            <Input disabled placeholder="Enter Mobile no" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Date of birth"
                            name="dob"
                            rules={[
                              {
                                required: true,
                                message: "Please input valid date!",
                              },
                            ]}
                            extra="Must be above 18 years old"
                          >
                            <DatePicker
                              defaultValue={moment("2015-01-01", "MM-DD-YYYY")}
                              style={{ width: "100%" }}
                              format={"MM-DD-YYYY"}
                              placeholder="Date of Birth"
                              disabledDate={(current) =>
                                current.isAfter(moment().subtract(18, "year"))
                              }
                              showToday={false}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="SSN Number"
                            name="ssn_number"
                            rules={[
                              {
                                required: true,
                                pattern: new RegExp(
                                  /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/
                                ),
                                message: "Please input valid SSN Number!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter SSN number" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Gender"
                            name="gender_id"
                            rules={[
                              {
                                required: true,
                                message: "Please select your gender",
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value={1}>Male</Radio>
                              <Radio value={2}>Female</Radio>
                              <Radio value={3}>Other</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Shirt Size"
                            name="shirt_size"
                            rules={[
                              {
                                required: true,
                                message: "Please input Shirt Size",
                              },
                            ]}
                          >
                            <Select
                              style={{
                                width: "100%",
                              }}
                              placeholder="Select shirt size"
                              options={[
                                {
                                  value: "XS",
                                  label: "XS",
                                },
                                {
                                  value: "S",
                                  label: "S",
                                },
                                {
                                  value: "M",
                                  label: "M",
                                },
                                {
                                  value: "L",
                                  label: "L",
                                },
                                {
                                  value: "XL",
                                  label: "XL",
                                },
                                {
                                  value: "XXL",
                                  label: "XXL",
                                },
                                {
                                  value: "XXXL",
                                  label: "XXXL",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <div className="float-right">
                            {current < steps.length - 1 && (
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}

                {current === 2 && (
                  <div className="container-fluid">
                    <h5 className="mb-0">Address Details</h5>
                    <hr />
                    <Form
                      form={form}
                      name="basic"
                      layout="vertical"
                      onFinish={onFinishAddress}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Row gutter={24}>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="Street Address1"
                            name="street_address1"
                            rules={[
                              {
                                required: true,
                                message: "Please input valid address!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Address 1" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="Street Address2"
                            name="street_address2"
                            rules={[
                              {
                                required: false,
                                message: "Please input valid address!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Address 2" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="City"
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: "Please input city!",
                              },
                            ]}
                          >
                            <Input placeholder="Enter City" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="State"
                            name="state"
                            rules={[
                              {
                                required: true,
                                message: "Please input State!",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              placeholder="Select State"
                              options={(usstate || []).map((d) => ({
                                value: d.abbreviation,
                                label: d.name,
                              }))}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} xl={8}>
                          <Form.Item
                            label="Zipcode"
                            name="zip"
                            rules={[
                              {
                                required: true,
                                pattern: new RegExp(/^[0-9]+$/),
                                message: "Please input valid Zipcode!",
                              },
                            ]}
                          >
                            <Input maxLength={5} placeholder="Enter Zipcode" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <div className="float-left">
                            {current > 0 && (
                              <Button onClick={() => prev()}>Previous</Button>
                            )}
                          </div>
                          <div className="float-right">
                            {current < steps.length - 1 && (
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}

                {current === 3 && (
                  <div className="container-fluid">
                    <h5 className="mb-0">Other Details</h5>
                    <hr />
                    <Form
                      form={form}
                      name="basic"
                      layout="vertical"
                      onFinish={onFinishProof}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                    >
                      <Row gutter={24}>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Government Picture ID "
                            name="government_document"
                            rules={[
                              {
                                required: true,
                                message: "Please upload valid document",
                              },
                            ]}
                          >
                            <FileUpload
                              fileList={fileList}
                              setFileList={setFileList}
                              existingFile={governmenturl}
                              setExistingFile={setgovernmenturl}
                              form={form}
                              name="government_document"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Upload Picture"
                            name="photo_copy"
                            rules={[
                              {
                                required: true,
                                message: "Please upload your picture",
                              },
                            ]}
                          >
                            <FileUpload
                              fileList={fileList1}
                              setFileList={setFileList1}
                              existingFile={photourl}
                              setExistingFile={setphotourl}
                              form={form}
                              name="photo_copy"
                            />
                          </Form.Item>
                        </Col>
                        {/* <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Retailer / Agent Agreement"
                            name="retaileragreement"
                            rules={[
                              {
                                required: false,
                                message: "Please upload document",
                              },
                            ]}
                          >
                            <FileUploadDoc
                              fileList={fileList2}
                              setFileList={setFileList2}
                              existingFile={retailerurl}
                              setExistingFile={setretailerurl}
                              form={form}
                              name="retaileragreement"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Supporting Documents"
                            name="supportdocuments"
                            rules={[
                              {
                                required: false,
                                message: "Please upload document",
                              },
                            ]}
                          >
                            <FileUploadDoc
                              fileList={fileList3}
                              setFileList={setFileList3}
                              existingFile={supporturl}
                              setExistingFile={setsupporturl}
                              form={form}
                              name="supportdocuments"
                            />
                          </Form.Item>
                        </Col> */}
                        <Col span={24}>
                          <div className="float-left">
                            {current > 0 && (
                              <Button
                                onClick={() => {
                                  prev();
                                  setFileList([]);
                                  setFileList1([]);
                                  setFileList2([]);
                                  setFileList3([]);
                                }}
                              >
                                Previous
                              </Button>
                            )}
                          </div>
                          <div className="float-right">
                            {current > 0 && (
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
                {current === 4 && (
                  <div className="container-fluid">
                    <hr />
                    <Form
                      form={form}
                      name="basic"
                      layout="vertical"
                      onFinish={onFinishTerms}
                      onFinishFailed={onFinishFailed}
                    >
                      <Row gutter={24}>
                        {/* <Col xs={24} lg={24} xl={24}>
                          <Typography>
                            <Paragraph>
                              I acknowledge receipt of DISCLOSURE REGARDING
                              PREPARATION OF BACKGROUND REPORT and “A Summary of
                              Your Rights Under the Fair Credit Reporting Act.”{" "}
                              <br />
                              By my signature below, I authorize procurement of
                              consumer reports and/or investigative consumer
                              reports by the Company/Employer named below and
                              authorize preparation of such reports by the named
                              CRA. I authorize without reservation law
                              enforcement agencies, academic institutions, data
                              repositories, credit bureaus, courts (federal,
                              state, and local), government agencies and
                              licensing bureaus, information service bureaus,
                              employers, and others having information about me
                              to furnish any and all information about me to the
                              Consumer Reporting Agency when requested. I
                              understand and authorize that these reports may be
                              obtained and prepared any time after receipt of
                              this authorization and during my employment, if
                              hired, unless prohibited by applicable law or I
                              withdraw my authorization in writing.
                            </Paragraph>
                          </Typography>
                        </Col> */}

                        {/* <Col xs={24} lg={24} xl={24}>
                          <Typography>
                            <Paragraph>
                              I agree that a photocopy or facsimile of this
                              authorization, whether signed electronically or
                              handwritten, has the same authority as the
                              original. If employed by the Company/Employer
                              named below, I agree that this authorization will
                              remain in effect throughout my employment unless
                              prohibited by applicable law or I withdraw my
                              authorization in writing.
                              <br /> <br /> I authorize Simpliverified to
                              conduct the Background check(s) described above. I
                              acknowledge I may request a hard copy of this
                              Disclosure and Authorization form by calling
                              Simpliverified at 855-837-1328
                              <br />
                            </Paragraph>
                            <Paragraph>
                              I understand that the information requested
                              regarding date of birth, race and sex is for the
                              sole purpose of gathering the above information
                              accurately, and will not be used to discriminate
                              against me in violation of any law. Read,
                              Acknowledged and Authorized- I authorize
                              Simpliverified to contact me at the following
                              phone Number{" "}
                              <strong>{onboardingdata.mobile_number}</strong>,
                              or email address{" "}
                              <strong>{onboardingdata.email}</strong> for
                              clarification of any information provided.
                            </Paragraph>
                          </Typography>
                        </Col> */}
                        <Col xs={24} lg={24} xl={24}>
                          <Form.Item
                            name="terms_check"
                            valuePropName="checked"
                            rules={[
                              {
                                validator: (_, value) =>
                                  value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error("Should accept agreement")
                                      ),
                              },
                            ]}
                          >
                            <Checkbox>
                              I,
                              <strong>
                                {userData?.first_name}&nbsp;
                                {userData?.last_name}
                              </strong>
                              ,hereby acknowledge that I have carefully reviewed
                              and understood the details provided to me. By
                              signing below, I provide my explicit consent,
                              confirming that the information provided is
                              accurate to the best of my knowledge.
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} xl={24}>
                          <p className="mt-2">
                            <strong>APPLICANT SIGNATURE </strong>
                          </p>
                          <SignatureCanvas
                            penColor="black"
                            canvasProps={{ className: "signature" }}
                            ref={sigRef}
                            onEnd={handleSignatureEnd}
                          />
                          <div className="text-center">
                            <Button type="dashed" onClick={clearSignature}>
                              Clear Signature
                            </Button>
                          </div>
                          <span className="mt-5">&nbsp;</span>
                        </Col>
                        <Col span={24}>
                          <div className="float-right">
                            {current < steps.length && (
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                          <div className="float-left">
                            {current > 0 && (
                              <Button onClick={() => prev()}>Previous</Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
                {current === 5 && (
                  <div className="container-fluid">
                    {onboardingdata?.status >= 4 ? (
                      <>
                        <Row gutter={24}>
                          <Col span={24}>
                            <Title className="text-center mt-3" level={4}>
                              Candidate Profile Progress
                            </Title>

                            <Descriptions
                              style={{ maxWidth: "500px", margin: "auto" }}
                              bordered
                            >
                              {onboardingdata.candidate_progress
                                .filter((number, index) => index === 1)
                                .map((number, index) => (
                                  <Descriptions.Item
                                    key={index}
                                    label={number.id}
                                    span={3}
                                  >
                                    {console.log(number)}
                                    {/* Completed Indication */}
                                    {number.status === 7 ||
                                    number.status === 1 ||
                                    number.status === 21 ? (
                                      <CheckCircleFilled
                                        style={{
                                          fontSize: "24px",
                                          color: "#04980a",
                                        }}
                                      />
                                    ) : null}
                                    {/* Pending indication */}
                                    <ClockCircleFilled
                                      style={{
                                        fontSize: "24px",
                                        color: "#e99c08",
                                      }}
                                    />
                                    {number.status === 6 ||
                                    number.status === 22 ? (
                                      <CloseCircleFilled
                                        style={{
                                          fontSize: "24px",
                                          color: "red",
                                        }}
                                      />
                                    ) : null}

                                    <span style={{ verticalAlign: "top" }}>
                                      {" "}
                                      {number.value}
                                    </span>
                                  </Descriptions.Item>
                                ))}
                            </Descriptions>
                          </Col>

                          <Col className="mt-4" span={24}>
                            <div className="row justify-content-md-center">
                              {onboardingdata?.status >= 4 ? (
                                onboardingdata?.status === 15 ||
                                onboardingdata?.status === 16 ||
                                onboardingdata?.status === 17 ||
                                onboardingdata?.status === 18 ||
                                onboardingdata?.status === 19 ? null : (
                                  <>
                                    <div class="col-md-auto col-sm-6 py-1">
                                      <div className="text-center">
                                        <Button
                                          onClick={() => getdata()}
                                          type="primary"
                                        >
                                          Refresh
                                        </Button>
                                      </div>
                                    </div>
                                    <div class="col-md-auto col-sm-6 py-1">
                                      <div className="text-center">
                                        <Button
                                          type="primary"
                                          onClick={onBoardTraining}
                                          loading={loading}
                                        >
                                          {onboardingdata?.training_check ===
                                          null
                                            ? "My Lyve Health Training"
                                            : onboardingdata?.training_check ===
                                              0
                                            ? "Complete Your Training"
                                            : "Training Completed"}
                                        </Button>
                                        <div style={{ marginTop: 15 }}>
                                          {onboardingdata?.training_check ===
                                          null
                                            ? `Click "My Lyve Heath Training" to start your training`
                                            : onboardingdata?.training_check ===
                                              0
                                            ? `Click "Complete your Training" to resume your training`
                                            : null}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        {onboardingdata?.status === 13 ? (
                          <Row gutter={24}>
                            <Col xs={24} lg={24} xl={24}>
                              <Title
                                className="text-center text-primary mt-3"
                                level={4}
                              >
                                Congratulations and welcome aboard.
                              </Title>
                              <Typography className="text-center">
                                <Paragraph>
                                  Please click above link to access application
                                </Paragraph>
                              </Typography>
                            </Col>
                          </Row>
                        ) : null}
                        {onboardingdata?.status === 15 ||
                        onboardingdata?.status === 16 ||
                        onboardingdata?.status === 17 ||
                        onboardingdata?.status === 18 ||
                        onboardingdata?.status === 19 ? (
                          <Form
                            form={form}
                            name="basic"
                            layout="vertical"
                            onFinish={radfailupdate}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                          >
                            <Row gutter={24}>
                              <Col xs={24} lg={24} xl={24}></Col>
                              <Col xs={24} lg={12} xl={12}>
                                <Form.Item
                                  label="Retailer / Agent Agreement"
                                  name="retaileragreement1"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please upload document",
                                    },
                                  ]}
                                >
                                  <FileUploadDoc
                                    fileList={fileList4}
                                    setFileList={setFileList4}
                                    existingFile={retailerurl1}
                                    setExistingFile={setretailerurl1}
                                    form={form}
                                    name="retaileragreement1"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={12} xl={12}>
                                <Form.Item
                                  label="Supporting Documents"
                                  name="supportdocuments1"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please upload document",
                                    },
                                  ]}
                                >
                                  <FileUploadDoc
                                    fileList={fileList5}
                                    setFileList={setFileList5}
                                    existingFile={supporturl1}
                                    setExistingFile={setsupporturl1}
                                    form={form}
                                    name="supportdocuments1"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <div className="float-left">
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                )}
                {current === 6 && (
                  <div className="container-fluid position-relative">
                    <div>
                      <section
                        id="pdf-section"
                        className="d-flex flex-column align-items-center w-100"
                      >
                        <Document
                          file="https://wieserp.s3.amazonaws.com/myLyve/lyveHealthFieldGuide_20231.pdf"
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page
                            pageNumber={pageNumber}
                            scale={scale}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                          />
                        </Document>
                        <ControlPanel
                          className="Position-relative bottom-0"
                          scale={scale}
                          setScale={setScale}
                          numPages={numPages}
                          pageNumber={pageNumber}
                          setPageNumber={setPageNumber}
                          file="https://wieserp.s3.amazonaws.com/myLyve/lyveHealthFieldGuide_20231.pdf"
                        />
                      </section>
                    </div>

                    {current > 0 && (
                      <Button
                        className="Position-absolute bottom-0"
                        onClick={() => prev()}
                      >
                        Previous
                      </Button>
                    )}
                    {pageNumber === 8 && (
                      <Button
                        className="float-right"
                        type="primary"
                        htmlType="submit"
                        onClick={finishTraining}
                        loading={loading}
                      >
                        Complete
                      </Button>
                    )}
                  </div>
                )}
                {current === 7 && (
                  <div className="container-fluid">
                    {onboardingdata?.status >= 4 ? (
                      <>
                        <Row gutter={24}>
                          <Col span={24}>
                            <Title className="text-center mt-3" level={4}>
                              Candidate Profile Progress
                            </Title>

                            <Descriptions
                              style={{ maxWidth: "500px", margin: "auto" }}
                              bordered
                            >
                              {onboardingdata.candidate_progress
                                .filter((number, index) => index === 1)
                                .map((number, index) => (
                                  <Descriptions.Item
                                    key={index}
                                    label={number.id}
                                    span={3}
                                  >
                                    {console.log(number)}
                                    {number.status === 7 ||
                                    number.status === 1 ||
                                    number.status === 21 ? (
                                      <CheckCircleFilled
                                        style={{
                                          fontSize: "24px",
                                          color: "#04980a",
                                        }}
                                      />
                                    ) : null}
                                    {number.status === 5 ||
                                    number.status === 0 ||
                                    number.status === 4 ? (
                                      <ClockCircleFilled
                                        style={{
                                          fontSize: "24px",
                                          color: "#e99c08",
                                        }}
                                      />
                                    ) : null}
                                    {number.status === 6 ||
                                    number.status === 22 ? (
                                      <CloseCircleFilled
                                        style={{
                                          fontSize: "24px",
                                          color: "red",
                                        }}
                                      />
                                    ) : null}

                                    <span style={{ verticalAlign: "top" }}>
                                      {" "}
                                      {number.value}
                                    </span>
                                  </Descriptions.Item>
                                ))}
                            </Descriptions>
                          </Col>

                          <Col className="mt-4" span={24}>
                            <div className="row justify-content-md-center">
                              {onboardingdata?.status >= 4 ? (
                                onboardingdata?.status === 15 ||
                                onboardingdata?.status === 16 ||
                                onboardingdata?.status === 17 ||
                                onboardingdata?.status === 18 ||
                                onboardingdata?.status === 19 ? null : (
                                  <>
                                    <div class="col-md-auto col-sm-6">
                                      <div className="text-center">
                                        <Button
                                          onClick={() => getdata()}
                                          type="primary"
                                        >
                                          Refresh
                                        </Button>
                                      </div>
                                    </div>
                                    <div class="col-md-auto col-sm-6">
                                      <div className="text-center">
                                        {onboardingdata?.training_check !==
                                          1 && (
                                          <Button
                                            type="primary"
                                            onClick={() => {
                                              setCurrent(current + 1);
                                              getdata();
                                            }}
                                          >
                                            {onboardingdata?.training_check ===
                                            null
                                              ? "My Lyve Health Training"
                                              : onboardingdata?.training_check ===
                                                0
                                              ? "Complete Your Training"
                                              : "Training Completed"}
                                          </Button>
                                        )}
                                        {onboardingdata?.candidate_progress[1]
                                          .password && (
                                          <div style={{ marginTop: 15 }}>
                                            Email :{" "}
                                            {onboardingdata
                                              ?.candidate_progress[1].email ??
                                              ""}
                                            <span style={{ marginLeft: 15 }}>
                                              Password :{" "}
                                              {onboardingdata
                                                ?.candidate_progress[1]
                                                .password ?? ""}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )
                              ) : null}

                              {onboardingdata?.status === 13 && (
                                <div class="col-md-auto col-sm-6">
                                  <div className="text-center">
                                    <a
                                      rel="noreferrer"
                                      href={
                                        onboardingdata?.agent_portal_url ?? ""
                                      }
                                      target="_blank"
                                    >
                                      <Button type="primary">
                                        Agent Application Link
                                      </Button>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                        {onboardingdata?.status === 13 ? (
                          <Row gutter={24}>
                            <Col xs={24} lg={24} xl={24}>
                              <Title
                                className="text-center text-primary mt-3"
                                level={4}
                              >
                                Congratulations and welcome aboard.
                              </Title>
                              <Typography className="text-center">
                                <Paragraph>
                                  Please click above link to access application
                                </Paragraph>
                              </Typography>
                            </Col>
                          </Row>
                        ) : null}
                        {onboardingdata?.status === 15 ||
                        onboardingdata?.status === 16 ||
                        onboardingdata?.status === 17 ||
                        onboardingdata?.status === 18 ||
                        onboardingdata?.status === 19 ? (
                          <Form
                            form={form}
                            name="basic"
                            layout="vertical"
                            onFinish={radfailupdate}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                          >
                            <Row gutter={24}>
                              <Col xs={24} lg={24} xl={24}>
                                {/* <Form.Item
                                  label="RAD ID"
                                  name="update_rad_id"
                                  rules={[
                                    {
                                      // pattern: new RegExp("^[a-zA-Z0-9]{0,9}$"),
                                      required: true,
                                      message: "Please input RAD ID",
                                    },
                                    {
                                      pattern: /^[A-Za-z0-9]+$/,
                                      message: "Please enter a valid RAD ID",
                                    },
                                  ]}
                                >
                                  <Input
                                    maxLength={9}
                                    placeholder="Enter RAD ID"
                                  />
                                </Form.Item> */}
                              </Col>
                              <Col xs={24} lg={12} xl={12}>
                                <Form.Item
                                  label="Retailer / Agent Agreement"
                                  name="retaileragreement1"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please upload document",
                                    },
                                  ]}
                                >
                                  <FileUploadDoc
                                    fileList={fileList4}
                                    setFileList={setFileList4}
                                    existingFile={retailerurl1}
                                    setExistingFile={setretailerurl1}
                                    form={form}
                                    name="retaileragreement1"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={12} xl={12}>
                                <Form.Item
                                  label="Supporting Documents"
                                  name="supportdocuments1"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please upload document",
                                    },
                                  ]}
                                >
                                  <FileUploadDoc
                                    fileList={fileList5}
                                    setFileList={setFileList5}
                                    existingFile={supporturl1}
                                    setExistingFile={setsupporturl1}
                                    form={form}
                                    name="supportdocuments1"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <div className="float-left">
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </Content>
        </Spin>

        <Footer
          style={{
            textAlign: "center",
          }}
        >
          © 2023 Wireless ERP System | My Lyve Health | Quality Healthcare For
          Everyone | Version :&nbsp;
          {appVer}
        </Footer>
      </Layout>
    </>
  );
}
