import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ControlPanel from "./ControlPanel";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = () => {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
    sessionStorage.setItem("pageNumber", numPages);
  }

  return (
    <div>
      <section
        id="pdf-section"
        className="d-flex flex-column align-items-center w-100"
      >
        <Document
          file="https://wieserp.s3.amazonaws.com/myLyve/Lyve+Health+Field+Guide_2023.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            scale={scale}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file="https://wieserp.s3.amazonaws.com/myLyve/Lyve+Health+Field+Guide_2023.pdf"
        />
      </section>
    </div>
  );
};

export default PDFReader;
