import { Button, Image, Upload, message } from "antd";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
const { Dragger } = Upload;

function FileUpload({
  fileList,
  setFileList,
  existingFile,
  setExistingFile,
  form,
  name,
}) {
  const props = {
    multiple: false,
    accept: ".png,.jpg",
    showUploadList: false,
    onRemove: (file) => {
      console.log(file);
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      form.setFieldValue(name, newFileList);
    },
    beforeUpload: (file) => {
      console.log(file);
      // const isPNG = file.type === "image/png" || file.type === "image/jpg";
      // if (!isPNG) {
      //   message.error(`${file.name} is not a jpg/png file`);
      // } else {}
      setFileList([file]);
      form.setFieldValue(name, [file]);
      return false;
    },
    fileList,
  };
  console.log(fileList, existingFile, name);
  return (
    <>
      <Dragger {...props} key={Math.random()}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Picture must not be blurry, text must be readable and full ID should
          appear in the frame and must be in png/jpg format.
        </p>
      </Dragger>

      {fileList?.length > 0 && (
        <div className="d-flex align-items-center py-1 mb-2">
          <Image width={150} src={URL.createObjectURL(fileList[0])} />
          <Button
            size="small"
            type="dashed"
            className="ml-2"
            danger
            onClick={() => {
              setFileList([]);
              form.setFieldValue(name, []);
            }}
          >
            <DeleteOutlined size={20} /> Remove
          </Button>
        </div>
      )}
      {fileList?.length === 0 && existingFile && (
        <div className="d-flex align-items-center py-1 mb-2">
          <Image width={150} src={existingFile} />
          <Button
            size="small"
            type="dashed"
            className="ml-2"
            danger
            onClick={() => {
              setExistingFile("");
              form.setFieldValue(name, []);
            }}
          >
            <DeleteOutlined size={20} /> Remove
          </Button>
        </div>
      )}
    </>
  );
}

export default FileUpload;
