import { useState, useEffect, useContext } from "react";
import TopHeader from './Header'
import { Button, Layout, Spin, Typography } from 'antd'
import { GlobalContext } from "./GlobalContext";
import { getOnboarding } from "./api";
import { Content, Footer } from "antd/lib/layout/layout";
import bcgimg from "./asset/bcg.svg";
import { Link } from "react-router-dom";
const { Paragraph, Title } = Typography;

function Home() {
    const { user } = useContext(GlobalContext);
    const [dataloading, setdataloading] = useState(false);
    const [onboardingdata, setonboardingdata] = useState('');
    useEffect(() => {
        if (user) {
            setdataloading(true)
            getOnboarding(user?.candidate_id, user?.token).then(res => {
                setonboardingdata(res?.data?.items)
                setdataloading(false)
            }).catch(err => {
                console.log(err)
                setdataloading(false)
            })
        }
    }, [user])
    return (
        <Layout className="layout">
            <TopHeader />
            <Spin spinning={dataloading}>
                <Content className="container p-0" style={{ minHeight: 'calc(100vh - 182px)' }}>
                    <div className="site-layout-content">
                        <div className="text-center mt-5">
                            <img src={bcgimg} className="img-fluid" alt=""></img>
                        </div>
                        <Title className="text-center mt-3" level={4}>
                            Background check process initiated under your profile
                        </Title>
                        <Typography className="text-center">
                            <Paragraph>
                                Process takes 1-5 Business Days, We will update you
                                via email
                            </Paragraph>
                        </Typography>
                        <div className="text-center">
                            <Link to='/onboarding'><Button type="primary">Complete Onboarding</Button></Link>
                        </div>
                    </div>
                </Content>
            </Spin>
            <Footer
                style={{
                    textAlign: "center",
                }}
            >
                © 2023 Excess Telecom Wireless | Wireless and Approved Affordable
                Connectivity Program Provider
            </Footer>
        </Layout>
    )
}

export default Home