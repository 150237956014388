import { Avatar, Dropdown, Space, message } from "antd";
import logohome from "./asset/logo-home.png";
import profile from "./asset/profile.png";
import { LogoutOutlined, LockOutlined } from "@ant-design/icons";
import { GlobalContext } from "./GlobalContext.js";
import { useContext, useEffect, useState } from "react";
import { Header } from "antd/lib/layout/layout";
import { useNavigate } from "react-router-dom";
import { Logout } from "./api";
import { Spin } from "antd";

function TopHeader() {
  const { user, setUser } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [userLanding, setuserLanding] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setuserLanding(false);
    }, 1000);
    if (!userLanding && !user) {
      navigate("/");
    }
    if (user) {
      setuserLanding(false);
    }
  }, [user]);

  const handlereset = () => {
    navigate("/changepassword");
  };
  const items = [
    // {
    //   key: "1",
    //   label: (
    //     <div className="cursor-pointer" onClick={handlereset}>
    //       <LockOutlined /> Change Password
    //     </div>
    //   ),
    // },
    {
      key: "1",
      label: (
        <div className="cursor-pointer" onClick={() => onLogout()}>
          <LogoutOutlined /> Logout
        </div>
      ),
    },
  ];
  const onLogout = () => {
    sessionStorage.removeItem("wuser");
    sessionStorage.removeItem("Candidate_values");
    sessionStorage.removeItem("candidate_id");
    setUser("");
    Logout(user?.token)
      .then((res) => {
        message.success("You have successfully logged out");
      })
      .catch((err) => message.error("Request Timed Out! Please try login"));
  };

  return (
    <>
      {userLanding && (
        <div className="pageloading">
          <Spin />
        </div>
      )}
      <Header>
        <div className="container p-0 d-flex justify-content-between align-items-top">
          <div className="logo-main">
            <img src={logohome} alt=""></img>
          </div>
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            dropdownRender={(menu) => (
              <div
                className="dropdown-content"
                style={{ backgroundColor: "#fff" }}
              >
                {menu}
              </div>
            )}
          >
            <div className="cursor-pointer">
              <Space style={{ color: "white" }}>
                <Avatar src={profile} /> Hi,
                {user?.name?.split(" ")[0] ?? user?.name}
              </Space>
            </div>
          </Dropdown>
        </div>
      </Header>
    </>
  );
}

export default TopHeader;
